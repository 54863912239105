import {
  ContentCopy,
  Delete,
  Edit,
  Forward,
  LocalOffer,
  Schedule,
} from "@mui/icons-material";
import { List, ListItem, Popover } from "@mui/material";
import MDBox from "lib/components/MDBox";
import { memo, useCallback, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { toast } from "react-toastify";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import DownloadIcon from "@mui/icons-material/Download";
import AppModal from "ui/AppModal";
import MessageForward from "components/IChat/MessageForward";
import MarkChatUnreadIcon from "@mui/icons-material/MarkChatUnread";
import Swal from "sweetalert2";
import { ConversationAPI } from "services/ConversationAPI";
import { useDispatch, useSelector } from "react-redux";
import { messageAction } from "store/message-slice";
import { VIEWS } from "constants/ChatConstants";
import { groupAction } from "store/group-slice";
import MediaQuery from "react-responsive";
import { v4 as uuidv4 } from "uuid";

const MessageMenu = ({
  targetEle,
  onEventPerform,
  messageData,
  message = undefined,
}) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(targetEle);
  const [showForwardModal, setShowForwardModal] = useState(false);
  const currentActiveRecipient = useSelector(
    (state) => state.chat.current_active_recipient
  );
  const currentActiveGroup = useSelector(
    (state) => state.group.current_active_group
  );
  const currentActiveView = useSelector(
    (state) => state.chat.current_active_view
  );

  const token = useSelector((state) => state.user.token);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDownload = (url) => {
    const extension = url.split(".").pop();
    const fileName = uuidv4() + "." + extension;
    const link = document.createElement("a");
    link.href = url;
    document.body.appendChild(link);
    link.setAttribute("download", fileName);
    link.setAttribute("target", "_blank");
    link.click();
    document.body.removeChild(link);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onEventPerform("POPOVER_CLOSED");
  };

  const deleteMessageHandler = useCallback(
    (messageId) => {
      setAnchorEl(null);
      onEventPerform("POPOVER_CLOSED");
      Swal.fire({
        title: "Are you sure want to delete?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        customClass: {
          container: {
            zIndex: "10000 !important",
          },
        },
      }).then((result) => {
        if (result.isConfirmed) {
          // delete the message....
          ConversationAPI.deleteConversation({ token, messageId })
            .then((res) => {
              const msgId = res.data;
              if (currentActiveView === VIEWS.GROUP_VIEW) {
                dispatch(
                  groupAction.deleteMessage({
                    msgId,
                    groupId: currentActiveGroup.groupId,
                  })
                );
              } else {
                dispatch(
                  messageAction.deleteMessage({
                    msgId,
                    recipientId: currentActiveRecipient.id,
                  })
                );
              }
            })
            .catch((e) => {
              console.log(e);
            });
        }
      });
    },
    [currentActiveRecipient, dispatch, currentActiveView, currentActiveGroup]
  );

  const forwardMessageToIC = () => {
    setAnchorEl(null);
    setShowForwardModal(true);
  };

  useEffect(() => {
    setAnchorEl(targetEle);
  }, [targetEle]);

  console.log("message_mg", message);
  return (
    <>
      <Popover
        onClose={handleClose}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPopover-paper": {
            backgroundColor: "white!important",
            display: "flex",
            justifyContent: "center",
            border: "solid 1px #ddd",
            boxShadow: "-4px 2px 6px 0px #979797",
            userSelect: "none",
          },
        }}
      >
        <List>
          {message.status === "scheduled" && (
            <ListItem
              sx={{
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              <MDBox
                onClick={() => {
                  setAnchorEl(null);
                  onEventPerform("SCHEDULE_EDIT", message);
                }}
                display="flex"
                justifyContent="row"
                sx={{
                  alignItems: "center",
                }}
              >
                <Edit sx={{ margin: "4px" }} />
                <span style={{ userSelect: "none" }}>Edit</span>
              </MDBox>
            </ListItem>
          )}
          {message.status === "scheduled" && (
            <ListItem
              sx={{
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              <MDBox
                onClick={() => {
                  setAnchorEl(null);
                  onEventPerform("CANCEL_SCHEDULE", message);
                }}
                display="flex"
                justifyContent="row"
                sx={{
                  alignItems: "center",
                }}
              >
                <Schedule sx={{ margin: "4px" }} />
                <span style={{ userSelect: "none" }}>Cancel schedule</span>
              </MDBox>
            </ListItem>            
          )}
          <ListItem
            sx={{
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={() => {
              setAnchorEl(null);
              deleteMessageHandler(messageData?.id);
            }}
          >
            <MDBox
              display="flex"
              justifyContent="row"
              sx={{
                alignItems: "center",
              }}
            >
              <Delete sx={{ margin: "4px" }} />
              <span style={{ userSelect: "none" }}>Delete</span>
            </MDBox>
          </ListItem>

          <ListItem
            sx={{
              fontSize: "14px",
              cursor: "pointer",
            }}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setAnchorEl(null);
              onEventPerform("OPEN_TAG");
            }}
          >
            <MDBox
              display="flex"
              justifyContent="row"
              sx={{
                alignItems: "center",
              }}
            >
              <LocalOffer sx={{ margin: "4px" }} />
              <span style={{ userSelect: "none" }}>Tags</span>
            </MDBox>
          </ListItem>

          <ListItem
            sx={{
              fontSize: "14px",
              cursor: "pointer",
              marginTop: "4px",
            }}
          >
            <CopyToClipboard
              onCopy={() => {
                toast.info("Link copied to clipboard");
              }}
              text={`${window.location.href}?messageId=${messageData?.id}&sendTo=${messageData?.sendTo}&phoneId=${messageData?.phoneId}`}
            >
              <MDBox
                display="flex"
                justifyContent="row"
                sx={{
                  alignItems: "center",
                }}
                onClick={() => {
                  setAnchorEl(null);
                  onEventPerform("SHARE_MESSAGE_LINK");
                }}
              >
                <ContentCopy sx={{ margin: "4px" }} />
                <span style={{ userSelect: "none" }}>Copy message link</span>
              </MDBox>
            </CopyToClipboard>
          </ListItem>

          <MediaQuery maxWidth={768}>
            <ListItem
              sx={{
                fontSize: "14px",
                cursor: "pointer",
                marginTop: "4px",
              }}
            >
              <CopyToClipboard
                onCopy={() => {
                  toast.info("Text copied to clipboard");
                }}
                text={message.message}
              >
                <MDBox
                  display="flex"
                  justifyContent="row"
                  sx={{
                    alignItems: "center",
                  }}
                  onClick={() => {
                    setAnchorEl(null);
                    /// onEventPerform('SHARE_MESSAGE_');
                  }}
                >
                  <ContentCopy sx={{ margin: "4px" }} />
                  <span style={{ userSelect: "none" }}>Copy message Text</span>
                </MDBox>
              </CopyToClipboard>
            </ListItem>
          </MediaQuery>

          <ListItem
            sx={{
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            <MDBox
              onClick={forwardMessageToIC}
              display="flex"
              justifyContent="row"
              sx={{
                alignItems: "center",
              }}
            >
              <ForwardToInboxIcon sx={{ margin: "4px" }} />
              <span>Forward to Internal Chat</span>
            </MDBox>
          </ListItem>

          {message.inbound && (
            <ListItem
              sx={{
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              <MDBox
                onClick={() => {
                  setAnchorEl(null);
                  onEventPerform("MARK_MESSAGE_UNREAD");
                }}
                display="flex"
                justifyContent="row"
                sx={{
                  alignItems: "center",
                }}
              >
                <MarkChatUnreadIcon sx={{ margin: "4px" }} />
                <span style={{ userSelect: "none" }}>Unread from here</span>
              </MDBox>
            </ListItem>
          )}

          {message?.media?.length > 0 && (
            <ListItem
              sx={{
                fontSize: "14px",
                cursor: "pointer",
              }}
            >
              <MDBox
                onClick={() => {
                  setAnchorEl(null);
                  message?.media?.map((_d) => {
                    handleDownload(_d.src);
                  });
                }}
                display="flex"
                justifyContent="row"
                sx={{
                  alignItems: "center",
                }}
              >
                <DownloadIcon sx={{ margin: "4px" }} />
                <span style={{ userSelect: "none" }}>Download</span>
              </MDBox>
            </ListItem>
          )}
        </List>
      </Popover>

      {showForwardModal && (
        <AppModal
          heading={"Forward To"}
          onModalClose={() => {
            setAnchorEl(undefined);
            setShowForwardModal(false);
            onEventPerform("POPOVER_CLOSED");
          }}
          closeOnClick={false}
        >
          <MessageForward
            message={message}
            setForwardModal={setShowForwardModal}
            isTelecommunication={true}
          />
        </AppModal>
      )}
    </>
  );
};
export default memo(MessageMenu);
