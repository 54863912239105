import { CircularProgress } from "@mui/material";
import { LeftChatContent } from "pages/Chat/ChatStyles";
import React, { useEffect, useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import AssignRecipient from "components/RecipientAssignment/AssignRecipient";
import AppModal from "ui/AppModal";
import { chatAction } from "store/chat-slice";
import MDBox from "lib/components/MDBox";
import LeftChatPanelRecipient from "./LeftChatPanel/LeftChatPanelRecipient";
import { chatVariablesAction } from "store/chat-variables-slice";
import { useLocation, useNavigate } from "react-router-dom";
import { RecipientAPI } from "services/Chat/RecipientAPI";

const LeftChatPanelContent = () => {
  const currentActiveView = useSelector(
    (state) => state.chat.current_active_view
  );
  const currentActivePhone = useSelector(
    (state) => state.chat.current_active_phone
  );
  const storedRecipientList = useSelector(
    (state) => state.chat[currentActiveView]
  );
  const [currRecipientList, setCurrRecipientList] = useState(
    storedRecipientList?.recipients
  );
  const activeCheckbox = useSelector(
    (state) => state.chatVariables.activeCheckbox
  );
  const currentActiveRecipient = useSelector(
    (state) => state.chat.current_active_recipient
  );
  const token = useSelector((state) => state.user.token);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [assignRecipient, setAssignRecipient] = useState(null);

  const handleMessageData = useCallback(
    async (messageId, sendTo, phoneId) => {
      const searchData = {
        id: messageId,
        sendTo: { id: sendTo },
        phone: { phoneId },
      };
      const recipientId = Number(searchData?.sendTo?.id);

      // Check if recipient already exists in the Redux recipient list
      const existingRecipient = currRecipientList.find(
        (recipient) => recipient.id === recipientId
      );

      if (existingRecipient) {
        dispatch(chatAction.setRecipientById(recipientId)); // Reset active recipient
        localStorage.setItem("searchMessage", JSON.stringify(searchData));
      } else {
        // Fetch recipient details from API if they don't exist in the list
        const response = await RecipientAPI.getRecipientById({
          recipientId,
          token,
        });
        if (response.data) {
          const recipient = response.data;

          // Add recipient to Redux list
          dispatch(chatAction.addRecipientsToFirst(recipient));

          // Navigate to the newly added recipient
          dispatch(chatAction.setRecipientById(recipientId));

          localStorage.setItem("searchMessage", JSON.stringify(searchData));
        }
      }
      // After processing, remove 'messageId', 'sendTo', and 'phoneId' from the URL
      const newSearch = new URLSearchParams(location.search);
      newSearch.delete("messageId");
      newSearch.delete("sendTo");
      newSearch.delete("phoneId");

      navigate(
        { pathname: location.pathname, search: newSearch.toString() },
        { replace: true }
      );
    },
    [currRecipientList]
  );

  const queryParams = new URLSearchParams(location.search); // Parse query parameters
  const messageId = queryParams.get("messageId"); // Get the 'messageId' parameter
  const sendTo = queryParams.get("sendTo");
  const phoneId = queryParams.get("phoneId");

  useEffect(() => {
    if (messageId) {
      handleMessageData(messageId, sendTo, phoneId);
    }
  }, [messageId, sendTo, phoneId]);

  useEffect(() => {
    // whenever this changed check if there is no selection then close the nav menu
    let anySelected = false;
    for (const itm in activeCheckbox) {
      if (activeCheckbox[itm].selected) {
        anySelected = true;
      }
    }
    if (!anySelected) {
      dispatch(chatVariablesAction.setIsCheckboxSelected(false));
    }
  }, [activeCheckbox]);

  const onAssignmentDone = (res) => {
    setAssignRecipient(null);
  };

  useEffect(() => {
    console.log("render in leftchatpanel due to recipient store change");
    setCurrRecipientList(storedRecipientList.recipients);
  }, [storedRecipientList.recipients]);
  return (
    <LeftChatContent id="scrollableRecipient">
      <InfiniteScroll
        style={{ overflowX: "hidden" }}
        dataLength={currRecipientList.length}
        next={() => {
          dispatch(chatAction.updateCurrentPage({ view: currentActiveView }));
        }}
        hasMore={
          storedRecipientList?.currentPage < storedRecipientList?.totalPages &&
          storedRecipientList?.totalPages > 1
        }
        loader={
          <MDBox
            width="100%"
            sx={{ overflow: "hidden" }}
            display="flex"
            justifyContent="center"
          >
            <CircularProgress />
          </MDBox>
        }
        scrollableTarget="scrollableRecipient"
      >
        <MDBox
          className="scrollableRecipient"
          diplay="flex"
          width="auto"
          height="auto"
        >
          {Array.isArray(currRecipientList) &&
            currRecipientList?.map((recipient, index) => (
              <LeftChatPanelRecipient
                key={"left_chat_panel" + recipient?.id}
                recipient={recipient}
                setAssignRecipient={setAssignRecipient}
                selected={currentActiveRecipient?.id === recipient?.id}
              />
            ))}
        </MDBox>
      </InfiniteScroll>
      {assignRecipient && (
        <AppModal
          heading={"Assign recipient to user"}
          onModalClose={() => {
            setAssignRecipient(null);
          }}
        >
          <AssignRecipient
            onAssignmentDone={onAssignmentDone}
            currentActivePhone={currentActivePhone}
            recipient={assignRecipient}
          />
        </AppModal>
      )}
    </LeftChatContent>
  );
};

export default React.memo(LeftChatPanelContent);
