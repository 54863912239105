import { useDispatch, useSelector } from "react-redux";
import ChatFooter from "../ChatFooter";
import { v4 as uuidv4 } from "uuid";
import { amountOfCreditToBeUsed } from "util/ChatUtil";
import MDTypography from "lib/components/MDTypography";
import { chatAction } from "store/chat-slice";
import useSend from "hooks/use-send";
import { useCallback } from "react";
import { memo } from "react";
import { messageAction } from "store/message-slice";
import AppConstants from "constants/AppConstants";
import { TELE_MESSAGE_STATUS } from "constants/ChatConstants";

/**
 *
 * @returns
 */
const NFooterWrapper = ({ messageState, setMessageState }) => {
  const dispatch = useDispatch();
  const currentActiveRecipient = useSelector(
    (state) => state.chat.current_active_recipient
  );
  const currentActivePhone = useSelector(
    (state) => state.chat.current_active_phone
  );
  const currentUser = useSelector((state) => state.userDetails.details);
  const isMergeView = useSelector((state) => state.app.mergeViewActive);
  const token = useSelector((state) => state.user.token);
  const { send } = useSend();

  const sendHandler = (params) => {
    const payload = buildMessageObj(params);
    if (params.scheduledAt) {
      payload.scheduledAt = params.scheduledAt;
    }
    /// Call the endpoints....
    // If attachment call sendMessageWithAttachement
    if (params.messageState?.attachement) {
      payload.messageAttchement = URL.createObjectURL(
        params.messageState.attachement
      );
    }
    send({
      msgPayload: payload,
      token,
      file: params?.messageState?.attachement,
    });
    // Append to last
    dispatch(
      messageAction.addMessage({
        message: payload,
        recipientId: payload.sendTo.id,
      })
    );
    dispatch(
      chatAction.updateLatestMessage({
        message: payload,
        recipientId: payload.sendTo.id,
      })
    );
  };

  const buildMessageObj = useCallback(
    (params) => {
      const msgPayload = {
        tempId: uuidv4(),
        sendTo: {
          id: currentActiveRecipient?.id,
          phone: currentActiveRecipient?.phone,
          countryCode: currentActiveRecipient?.countryCode
            ? currentActiveRecipient?.countryCode
            : "1",
        },
        sendByPhone: {
          phoneId: currentActivePhone?.phoneId,
        },
        sendByUser: {
          userId: currentUser.userId,
          fullName: currentUser.fullName,
          profilePic: currentUser.profilePic,
          signature: currentUser?.userSettings?.teleSignature,
        },
        domain: currentUser?.company?.slug
          ? currentUser?.company?.slug
          : "bliq",
        mediumUsed: "APP",
        tags: [],
        creditUsed: amountOfCreditToBeUsed(params.messageState.message),
        message: params.messageState.message,
        inbound: false,
        wMessageType: "text",
        status: TELE_MESSAGE_STATUS.prepare, //MObO : sending...
        timeZone: "UTC",
        type: params.type,
        messageLogType: "NORMAL",
      };
      if (isMergeView) {
        msgPayload.sendByPhone = {
          phoneId: currentActiveRecipient.appPhone.phoneId,
        };
      }
      return msgPayload;
    },
    [currentUser, currentActiveRecipient, currentActivePhone, isMergeView]
  );

  return (
    <>
      {currentActiveRecipient?.phoneBlocked && (
        <MDTypography
          color="error"
          fontSize={"14px"}
          sx={{ lineHeight: "1.2", padding: "3px", textAlign: "center" }}
        >
          {AppConstants.RECIPIENT_OPT_OUT}
        </MDTypography>
      )}
      <ChatFooter
        autoFocus={!currentActiveRecipient?.phoneBlocked}
        messageState={messageState}
        setMessageState={setMessageState}
        sendMessageHandler={sendHandler}
      />
    </>
  );
};
export default memo(NFooterWrapper);
