import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ConversationAPI } from "services/ConversationAPI";
import { messageAction } from "store/message-slice";

const useChatV1 = () => {
  const dispatch = useDispatch();
  const currentActivePhone = useSelector(
    (state) => state.chat.current_active_phone
  );
  const currentActiveRecipient = useSelector(
    (state) => state.chat.current_active_recipient
  );
  const token = useSelector((state) => state.user.token);
  const rConversationState = useSelector(
    (state) => state.message.conversation[currentActiveRecipient?.id]
  );

  // State for searchRecipient
  const [searchRecipient, setSearchRecipient] = useState(() =>
    JSON.parse(localStorage.getItem("searchMessage"))
  );

  // State for searchRecipient
  const [searchMode, setSearchMode] = useState(searchRecipient ? true : false);

  // Ref to track the last processed recipient
  const lastRecipientIdRef = useRef(null);

  /**
   * Load conversations for the active recipient.
   * Handles pagination with direction ("next" or "prev").
   */
  const loadConversation = useCallback(
    async (direction = null) => {
      if (!currentActiveRecipient) return;

      const { nextPageCursor, prevPageCursor } = rConversationState || {};
      const payload = [
        {
          field: "phone_id",
          key: "phoneId",
          operator: "eq",
          value: currentActivePhone?.phoneId,
        },
        {
          field: "recipient",
          key: "recipient",
          operator: "eq",
          value: currentActiveRecipient?.phone,
        },
      ];

      const cursorPayload =
        direction === "next"
          ? { nextPageCursor }
          : direction === "prev"
          ? { prevPageCursor }
          : {};

      try {
        const res = await ConversationAPI.getConversationsv1({
          token,
          payload,
          ...cursorPayload,
        });

        const messages = res.data.content.reverse() || [];

        if (!direction) {
          // Initial load
          dispatch(
            messageAction.setConversation({
              messages,
              recipientId: currentActiveRecipient.id,
              nextPageCursor: res.data.nextPageCursor,
              prevPageCursor: res.data.prevPageCursor,
            })
          );
        } else {
          // Pagination
          dispatch(
            messageAction.addMoreConversationToCurrent({
              messages,
              recipientId: currentActiveRecipient.id,
              ...(direction === "next" && {
                nextPageCursor: res.data.nextPageCursor,
              }),
              ...(direction === "prev" && {
                prevPageCursor: res.data.prevPageCursor,
              }),
            })
          );
        }
      } catch (error) {
        console.error("Error fetching conversations:", error);
      }
    },
    [currentActivePhone, currentActiveRecipient, rConversationState, token]
  );

  /**
   * Handle searchRecipient initialization and data fetching.
   */
  useEffect(() => {
    const fetchSearchConversation = async () => {
      if (!searchRecipient) return;

      // Clear cached messages
      dispatch(
        messageAction.setConversation({
          recipientId: searchRecipient?.sendTo?.id,
          messages: [],
          nextPageCursor: null,
          prevPageCursor: null,
        })
      );

      try {
        const res = await ConversationAPI.getConversationsByMessageId({
          messageId: searchRecipient.id,
          token,
        });

        const messages = res.data.content.reverse() || [];

        dispatch(
          messageAction.setConversation({
            messages,
            recipientId: searchRecipient?.sendTo?.id,
            nextPageCursor: res.data.nextPageCursor,
            prevPageCursor: res.data.prevPageCursor,
            targetMessageId: searchRecipient.id,
          })
        );

        // Cleanup
        localStorage.removeItem("searchMessage");
        setSearchMode(false);
      } catch (error) {
        console.error("Error fetching search result:", error);
      }
    };

    fetchSearchConversation();
  }, [searchRecipient]);

  /**
   * Handle recipient change and load fresh messages.
   */
  useEffect(() => {
    const currentRecipientId = currentActiveRecipient?.id;

    if (
      currentRecipientId &&
      lastRecipientIdRef.current !== currentRecipientId &&
      !searchRecipient
    ) {
      console.log(`Recipient changed to: ${currentRecipientId}`);

      // Clear cached messages for the new recipient
      dispatch(
        messageAction.setConversation({
          recipientId: currentRecipientId,
          messages: [],
          nextPageCursor: null,
          prevPageCursor: null,
        })
      );

      // Fetch fresh messages for the new recipient
      loadConversation();

      // Update the last processed recipient
      lastRecipientIdRef.current = currentRecipientId;
    }
  }, [currentActiveRecipient]);

  useEffect(() => {
    if (!searchMode) {
      setSearchRecipient(null);
    }
  }, [searchMode]);

  return { loadConversation };
};

export default useChatV1;
