import CrowdfeatureSetting from "./CrowdfeatureSetting";
import PrivacyPolicy from "./PrivacyPolicy";
import Whatsapp from "components/Whatsapp";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import MDBox from "lib/components/MDBox";
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  InsertDriveFile,
  Key,
  Policy,
  Settings,
  WhatsApp,
} from "@mui/icons-material";
import AppModal from "ui/AppModal";
import SyncGoogleContacts from "components/Google/SyncGoogleContacts";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { CompanyAPI } from "services/CompanyAPI";
import CompanyApi from "components/API/CompanyApi";
import SetCompanyFooter from "./SetCompanyFooter";

const CURR_MODAL = {
  FEATURE_SETTING: "crowdfunding",
  PRIVACY_POLICY: "privacy_policy",
  WHATSAPP: "WHATSAPP",
  API_ACCESS: "API_ACCESS",
  DISA_CONFIG: "disa",
  API_TRIGGER_CELL_CALL: "api_triggered_call",
  FOOTER: "footer",
  NONE: "none",
};

const CompanyDetails = ({ company, onEdited }) => {
  const currUser = useSelector((state) => state.user);
  const [cardknoxKey, setCardknoxKey] = useState("");
  const [settingEditing, setSettingEditing] = useState(false);
  const [modal, setModal] = useState(CURR_MODAL.NONE);

  const saveCardknoxKey = useCallback(() => {
    const payload = {
      company_id: company.company_id,
      cardknox_key: cardknoxKey,
    };
    CompanyAPI.updateCardknoxKey({ token: currUser.token, payload })
      .then((res) => {
        toast.success("Cardknox key updated successfully.");
        setSettingEditing(false);
        onEdited({
          ...company,
          cardknox_key: cardknoxKey,
        });
      })
      .catch((e) => {
        toast.error(e?.response?.data?.message);
      });
  }, [
    currUser.token,
    company?.company_id,
    cardknoxKey,
    setSettingEditing,
    onEdited,
  ]);

  const openFeatureSettings = (fn) => {
    if (fn === CURR_MODAL.FEATURE_SETTING) {
      setModal(CURR_MODAL.FEATURE_SETTING);
    } else if (fn === CURR_MODAL.DISA_CONFIG) {
      setModal(CURR_MODAL.DISA_CONFIG);
    }
  };

  useEffect(() => {
    if (company.cardknox_key) {
      setCardknoxKey(company.cardknox_key);
    }
  }, [company]);

  return (
    <>
      <MDBox
        ml={2}
        sx={{ padding: "12px", overflowY: "auto" }}
        display="flex"
        flexDirection="column"
      >
        <MDBox display="flex" width="100%" justifyContent="center" mb={2}>
          <Typography>{company?.company_name} Settings</Typography>
        </MDBox>

        <MDBox display="flex" flexDirection="column">
          <Typography variant="button" fontWeight={500}>
            CardKnox Key
          </Typography>
          <TextField
            sx={{ width: "100%" }}
            value={cardknoxKey}
            onChange={(e) => {
              setCardknoxKey(e.target.value);
              if (!settingEditing) {
                setSettingEditing(true);
              }
            }}
          />
        </MDBox>

        {settingEditing && (
          <MDBox display="flex" justifyContent="flex-end" mt={2}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0px",
                color: "white!important",
                border: "unset",
                marginLeft: "12px",
                height: "32px",
                minHeight: "unset",
              }}
              onClick={saveCardknoxKey}
            >
              Save changes
            </Button>
          </MDBox>
        )}

        {company?.products_subscribed?.length > 0 && (
          <MDBox display="flex" flexDirection="column" mt={2}>
            <Typography variant="h6">Features subscribed</Typography>
            {company.products_subscribed.map((p) => (
              <MDBox
                key={p?.product?.product_id}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    }
                    label={p?.product?.name || ""}
                  />
                </FormGroup>
                {p?.product?.product_key === CURR_MODAL.FEATURE_SETTING && (
                  <Tooltip title={`${p?.product?.name} settings`}>
                    <Settings
                      onClick={() =>
                        openFeatureSettings(p?.product?.product_key)
                      }
                      sx={{
                        cursor: "pointer",
                        width: "1.2rem",
                        height: "1.2rem",
                      }}
                    />
                  </Tooltip>
                )}
              </MDBox>
            ))}
          </MDBox>
        )}

        <MDBox mt={2}>
          <MDBox onClick={() => setModal(CURR_MODAL.WHATSAPP)}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0px",
                color: "white!important",
                border: "unset",
                height: "32px",
                minHeight: "unset",
              }}
              endIcon={<WhatsApp />}
            >
              whatsapp
            </Button>
          </MDBox>

          <MDBox mt={1} onClick={() => setModal(CURR_MODAL.API_ACCESS)}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0px",
                color: "white!important",
                border: "unset",
                height: "32px",
                minHeight: "unset",
              }}
              endIcon={<Key />}
            >
              API Access
            </Button>
          </MDBox>

          <MDBox mt={1} onClick={() => setModal(CURR_MODAL.PRIVACY_POLICY)}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0px",
                color: "white!important",
                border: "unset",
                height: "32px",
                minHeight: "unset",
              }}
              endIcon={<Policy />}
            >
              Privacy policy
            </Button>
          </MDBox>
          <MDBox mt={1} onClick={() => setModal(CURR_MODAL.FOOTER)}>
            <Button
              variant="contained"
              sx={{
                borderRadius: "0px",
                color: "white!important",
                border: "unset",
                height: "32px",
                minHeight: "unset",
              }}
              endIcon={<InsertDriveFile />}
            >
              {"Receipt layout"}
            </Button>
          </MDBox>
        </MDBox>

        <MDBox mt={1}>
          <Divider
            variant="horizontal"
            component="div"
            sx={{
              "&.MuiDivider-root": {
                backgroundColor: "#e9d5e9",
              },
            }}
          />
          <GoogleOAuthProvider
            key={company.company_id}
            clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}
          >
            <SyncGoogleContacts company={company} />
          </GoogleOAuthProvider>
        </MDBox>
      </MDBox>

      {modal === CURR_MODAL.FEATURE_SETTING && (
        <AppModal
          rootStyle={{
            "&.MuiModal-root": {
              width: "90%",
              left: "10%",
            },
          }}
          customStyle={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            border: "unset",
          }}
          heading={" "}
          onModalClose={() => setModal(CURR_MODAL.NONE)}
          mediaModalClose={false}
        >
          <CrowdfeatureSetting company={company} />
        </AppModal>
      )}
      {modal === CURR_MODAL.PRIVACY_POLICY && (
        <AppModal
          rootStyle={{
            "&.MuiModal-root": {
              width: "99%",
              left: "1%",
            },
          }}
          customStyle={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            border: "unset",
          }}
          heading={" "}
          onModalClose={() => setModal(CURR_MODAL.NONE)}
          mediaModalClose={false}
        >
          <PrivacyPolicy company={company} />
        </AppModal>
      )}

      {modal === CURR_MODAL.WHATSAPP && (
        <AppModal
          rootStyle={{
            "&.MuiModal-root": {
              width: "90%",
              left: "10%",
            },
          }}
          customStyle={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            border: "unset",
          }}
          heading={" "}
          onModalClose={() => setModal(CURR_MODAL.NONE)}
          mediaModalClose={false}
        >
          <Whatsapp company={company} />
        </AppModal>
      )}

      {modal === CURR_MODAL.FOOTER && (
        <AppModal
          rootStyle={{
            "&.MuiModal-root": {
              width: "90%",
              left: "10%",
            },
          }}
          customStyle={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            border: "unset",
          }}
          heading={" "}
          onModalClose={() => setModal(CURR_MODAL.NONE)}
          mediaModalClose={false}
        >
          <SetCompanyFooter
            companyFooter={company?.footer}
            onFooterUpdated={(res) => {
              onEdited({
                ...company,
                footer: res,
              });
            }}
            companyId={company?.company_id}
          />
        </AppModal>
      )}

      {modal === CURR_MODAL.API_ACCESS && (
        <AppModal
          rootStyle={{
            "&.MuiModal-root": {
              width: "90%",
              left: "10%",
            },
          }}
          customStyle={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            border: "unset",
          }}
          heading={" "}
          onModalClose={() => setModal(CURR_MODAL.NONE)}
          mediaModalClose={false}
        >
          <CompanyApi company={company} />
        </AppModal>
      )}
    </>
  );
};
export default CompanyDetails;
