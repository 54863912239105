import { BASE_URL } from "constants/Endpoints";
import axios from "../../core/axios";

export const SyncrAPI = {

    saveGeneratedCode : async({token,payload}) => {
        return await axios.post(`${BASE_URL}/api/v1/sms/save`,JSON.stringify(payload),{
            headers: {
                'Authorization': 'Bearer ' + token,
                "Content-Type": "application/json"
            }
        });
    }
}