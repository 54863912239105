import { Avatar, Checkbox, Tooltip } from "@mui/material";
import { getGroups } from "api/group";
import useHttp from "hooks/use-http";
import { UserChatBlock } from "pages/Chat/ChatStyles";
import { LeftChatContent } from "pages/Chat/ChatStyles";
import { useEffect, useState, forwardRef } from "react";
import Moment from "react-moment";
import EditIcon from '@mui/icons-material/Edit';
import CampaignIcon from '@mui/icons-material/Campaign';
import { useDispatch, useSelector } from "react-redux";
import AppModal from "ui/AppModal";
import BroadcastDetails from "./BroadcastDetails";
import MDTypography from "lib/components/MDTypography";
import MDBox from "lib/components/MDBox";
import { CALENDAR_STRING } from "constants/ChatConstants";
import { groupAction } from "store/group-slice";
import { useCallback, useImperativeHandle } from "react";
import { getRecipientFormatted } from "util/GroupUtil";
import { getDateConvertedFromUTC } from "util/UtilityFunctions";

const BroadcastView = ({ onEventPerform, newGroups, isCheckboxSelected }, ref) => {

  const dispatch = useDispatch();

  const token = useSelector(state => state.user.token);
  const currentActivePhone = useSelector(state => state.chat.current_active_phone);
  const [checkedState, setCheckedState] = useState(new Map());
  const currentSelected = useSelector(state => state.group.current_active_group);
  const groups = useSelector(state => state.group.groups);
  const [showGroupDetailsModal, setGroupDetailsModal] = useState(false);
  const { sendRequest: getGroupsFn, status, data, error } = useHttp(getGroups, false);
  const groupFetched = useSelector(state => state.group.groupFetched);

  const viewGroupDetailHandler = (id) => {
    setGroupDetailsModal(true);
  }

  const onEventPerformInGroupDetails = useCallback((action, data) => {
    if (action === 'GROUP_UPDATED') {
      const newGroupsState = groups.map(itm => {
        if (itm.groupId === data.groupId) {
          return data;
        }
        else return itm;
      });
      dispatch(groupAction.setGroups(newGroupsState));
    }
    if (action === 'GROUP_DELETED') {
      setGroupDetailsModal(false);
    }
  }, [currentSelected, groups]);

  const onCheckedEvent = (key) => {
    const newCheckedState = new Map();
    for (const item of checkedState.entries()) {
      if (item[0] === key) {
        newCheckedState.set(item[0], { selected: !item[1].selected });
      }
      else {
        newCheckedState.set(item[0], { selected: item[1].selected });
      }
    }
    setCheckedState(newCheckedState);
    onEventPerform("LEFT_CHAT_PANEL_CONTENT_CHECKED", { key, value: newCheckedState.get(key) });
  }
  const isCheck = (key) => {
    const val = checkedState.get(key)?.selected;
    return val;
  }
  useImperativeHandle(ref, () => {
    return {
      openDetail: (group) => {
        dispatch(groupAction.setCurrentActiveGroup(group));
        viewGroupDetailHandler(group.groupId);
      }
    }
  }, []);
  useEffect(() => {
    const newCheckedState = new Map();
    for (const item of checkedState.entries()) {
      let messageIds = [];
      // if(data){
      //   messageIds = conversationState.map(item => item.id);
      // } 
      newCheckedState.set(item[0], { selected: isCheckboxSelected, messageIds: messageIds });
    }
    setCheckedState(newCheckedState);
  }, [isCheckboxSelected]);

  useEffect(() => {
    if (!groupFetched) {
      getGroupsFn({ phoneId: currentActivePhone?.phoneId, token });
    }
  }, [currentActivePhone?.phoneId, groupFetched])

  useEffect(() => {
    if (newGroups?.length > 0) {
      const lastGroup = newGroups[newGroups.length - 1];
      const newGroupsState = [lastGroup, ...groups];
      dispatch(groupAction.setGroups(newGroupsState));
    }
  }, [newGroups]);

  useEffect(() => {
    if (status === 'completed') {
      if (data) {
        dispatch(groupAction.concateGroups(data));
        const currMap = new Map();
        for (const i of data) {
          currMap.set(i.groupId, {
            selected: false,
            messageIds: []
          });
        }
        setCheckedState(currMap);
        if (data.length > 0) {
          dispatch(groupAction.setGroupFetched(true));
        }
      }
    }
  }, [status, error, data]);

  return (
    <>
      {groups?.length > 0 ? (
        <LeftChatContent width="auto">
          {groups?.map((group, index) => (
            <UserChatBlock
              onClick={() => dispatch(groupAction.setCurrentActiveGroup(group))}
              currentSelected={currentSelected?.groupId === group.groupId}
              key={group.groupId + index}
            >
              {isCheckboxSelected && (
                <Checkbox
                  onChange={() => onCheckedEvent(group.groupId)}
                  checked={isCheck(group.groupId)}
                  className="custom-checkbox"
                />
              )}
              <MDBox key={group.groupId + index} component="li" display="flex" alignItems="center" py={1} mb={1}>
                <MDBox mr={2} className={`account-user-icon`} style={{ overflow: 'unset' }}>
                  <Avatar sx={{ bgcolor: "#1268db" }}>
                    <CampaignIcon />
                  </Avatar>
                </MDBox>
                <MDBox display="flex" flexDirection="column" alignItems="flex-start" justifyContent="center">
                  <MDTypography sx={{ fontWeight: "400" }} variant="button" fontWeight="medium">
                    {group.name}
                  </MDTypography>
                  <Tooltip title={getRecipientFormatted(group?.recipientPhone)}>
                    <MDTypography variant="caption" color="text" sx={{ fontWeight: "400", textOverflow: 'ellipsis', overflow: 'hidden', maxHeight: '32px!important' }}>
                      {getRecipientFormatted(group?.recipientPhone)}
                    </MDTypography>
                  </Tooltip>
                </MDBox>
              </MDBox>
              <MDBox ml="auto" sx={{ minWidth: "52px!important" }}>
                <MDTypography fontSize={"12px"}>
                  <Moment calendar={CALENDAR_STRING}>
                    {getDateConvertedFromUTC(group?.createdAt)}
                  </Moment>
                </MDTypography>
              </MDBox>
              <Tooltip title="Edit group">
                <EditIcon onClick={(event) => { viewGroupDetailHandler(group.groupId) }} style={{ color: '#b5c2dd', cursor: 'pointer', height: '18px', width: '18px' }} />
              </Tooltip>
            </UserChatBlock>
          ))}
          <div style={{height:'40px'}}></div>
        </LeftChatContent>
      ) :
        <LeftChatContent width="auto">
          <div style={{ textAlign: 'center', marginTop: '300px', display: 'flex', alignItems: 'center', justifyContent: 'center', fontWeight: 'bold', color: '#344767', fontSize: '18px' }}>
            <div>No Groups yet...</div>
          </div>
        </LeftChatContent>
      }
      {showGroupDetailsModal &&
        <AppModal
          heading={' '}
          rootStyle={
            {
              "&.MuiModal-root": {
                width: '80%',
                left: '20%'
              }
            }
          }
          customStyle={{
            width: "100%",
            height: "100%",
            overflowY: "auto",
            border: "unset",
          }}
          onModalClose={() => setGroupDetailsModal(false)}
        >
          <BroadcastDetails key={"details_" + currentSelected?.groupId} currentActivePhone={currentActivePhone} groupId={currentSelected?.groupId} onEventPerform={onEventPerformInGroupDetails} />
        </AppModal>
      }
    </>
  );
}
export default forwardRef(BroadcastView);