import {
  MessageWrapper,
  MessageChat,
  MainChatPanel,
} from "pages/Chat/ChatStyles";
import { useDispatch, useSelector } from "react-redux";
import NoContent from "../NoContent";
import React, {
  useCallback,
  useEffect,
  useState,
  memo,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import MDBox from "lib/components/MDBox";
import { CircularProgress, SpeedDial } from "@mui/material";
import SingleRightMessage from "../SingleRightMessage";
import { MESSAGE_EVENTS, VIEWS } from "constants/ChatConstants";
import SingleLeftMessage from "../SingleLeftMessage";
import AssignmentDetails from "../AssignmentDetails";
import useScrollToBottom from "hooks/use-scroll-to-bottom";
import useChat from "hooks/use-chat";
import useChatV1 from "hooks/use-chatv1";
import { chatVariablesAction } from "store/chat-variables-slice";
import InfiniteScroll from "react-infinite-scroll-component";
import { chatAction } from "store/chat-slice";
import { messageAction } from "store/message-slice";
import { KeyboardArrowDown } from "@mui/icons-material";

const NConversation = ({ setMessageState }) => {
  // useChat();
  // useChatV1();
  const { loadConversation } = useChatV1();

  const dispatch = useDispatch();
  const currentActiveRecipient = useSelector(
    (state) => state.chat.current_active_recipient
  );
  const view = useSelector((state) => state.chat.current_active_view);
  const scrollChatBottomBtn = useSelector(
    (state) => state.chatVariables.scrollChatBottomBtn
  );
  const conversation = useSelector(
    (state) => state.message.conversation[currentActiveRecipient?.id]
  );
  console.log("Conversations", conversation);
  const messages = conversation?.messages;
  const currentMessageView = useSelector(
    (state) => state.chat.current_message_view
  );
  const timezone = useSelector(
    (state) => state.userDetail?.details?.userSettings?.timeZone
  );
  const activeCheckbox = useSelector(
    (state) => state.chatVariables.activeCheckbox
  );
  const isCheckboxSelected = useSelector(
    (state) => state.chatVariables.isCheckboxSelected
  );
  const targetMessageId = useSelector(
    (state) =>
      state.message.conversation[currentActiveRecipient?.id]?.targetMessageId
  );

  // console.log("Target ", targetMessageId);

  const [inlineSearch, setInlineSearch] = useState({
    search: false,
    pattern: "",
    criterion: "NORMAL_SEARCH",
  });
  const [lastReadMessage, setLastReadMessage] = useState();
  // const [isAutoScroll, setIsAutoScroll] = useState(false);
  const [scroll, setScroll] = useState(true);
  const [scrollDirection, setScrollDirection] = useState("prev");
  const { scrollRef, scrollToBottom } = useScrollToBottom(
    messages,
    lastReadMessage,
    scroll,
    targetMessageId
  );

  const previousScrollTop = useRef(0);
  const previousScrollHeight = useRef(0);
  const blockScrollRef = useRef(false);
  const [isAPIProgress, setIsAPIProgress] = useState(false);
  // let previousScrollTop = 0;

  const loadMore = (direction) => {
    if (isAPIProgress) {
      return;
    }
    console.log("Scroll Load Morwee", isAPIProgress);
    if (scroll) {
      setScroll(false);
    }
    setIsAPIProgress(true);
    loadConversation(direction);
  };

  const handleRefresh = () => {
    console.log("Ulta CHl");
  };

  const handleScroll = (e) => {
    // if (isAutoScroll) {
    //   setIsAutoScroll(false);
    //   console.log("Scroll VAlue: Returning Auto Scroll");
    //   return;
    // }

    console.log("Scroll VAlue: Chal Rha hu");
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;

    if (scrollTop < previousScrollTop.current) {
      console.log("Scroll Direction : Prev");
      setScrollDirection("prev");
    } else if (scrollTop >= previousScrollTop.current) {
      console.log("Scroll Direction : Next");
      setScrollDirection("next");

      if (
        // scrollTopEvent + clientHeight >= scrollHeight - 5
        Math.abs(scrollTop) - 500 < 0 &&
        Boolean(conversation?.nextPageCursor)
      ) {
        loadMore("next");
      }
    }

    previousScrollTop.current = scrollTop;

    console.log(
      "Scroll values in scroll",
      scrollTop,
      scrollHeight,
      clientHeight,
      previousScrollTop.current
    );
  };

  const onEventPerFormInMessageComponent = useCallback(
    (action, data) => {
      if (action === "CHECKBOX") {
        const recipientId = currentActiveRecipient?.id;
        if (!data.checked) {
          dispatch(
            chatVariablesAction.pushId({ key: recipientId, id: data.id })
          );
        } else {
          const newMap = activeCheckbox[recipientId]?.messageIds.filter(
            (item) => item !== data.id
          );
          dispatch(
            chatVariablesAction.updateIds({ ids: newMap, key: recipientId })
          );
        }
      }
    },
    [currentActiveRecipient, activeCheckbox, dispatch]
  );

  const isPrev = () => {
    const value = Boolean(targetMessageId)
      ? scrollDirection === "prev"
      : scrollDirection === "prev" || !Boolean(conversation?.nextPageCursor);
    console.log("Scroll IsPrev", value);
    return value;
  };

  const formatMessages = useMemo(
    () => (message, index) => {
      const showReadBy = index === 0 && messages[index]?.inbound;
      if (
        message?.messageEvent === MESSAGE_EVENTS.CONVERSATION_ASSIGNED ||
        message?.messageEvent === MESSAGE_EVENTS.CONVERSATION_UNASSIGNED
      ) {
        return (
          <AssignmentDetails
            key={`assignment_${index}`}
            message={message}
            index={index}
            timezone={timezone}
          />
        );
      }

      if (view === VIEWS.SCHEDULED_VIEW) {
        return message?.inbound === false && message?.status === "scheduled" ? (
          <SingleRightMessage
            key={`scheduled_right_${index}`}
            message={message}
            user={message?.sendByUser}
            setMessageState={setMessageState}
            check={activeCheckbox[currentActiveRecipient?.id]?.selected}
            searchActive={inlineSearch}
            onEventPerform={onEventPerFormInMessageComponent}
            checkboxSelected={isCheckboxSelected}
          />
        ) : (
          <></>
        );
      }

      return message?.inbound ? (
        <SingleLeftMessage
          key={`left_msg_${message.id}_${index}`}
          message={message}
          user={message?.sendByUser}
          check={activeCheckbox[currentActiveRecipient?.id]?.selected}
          searchActive={inlineSearch}
          showReadBy={showReadBy}
          checkboxSelected={isCheckboxSelected}
          onEventPerform={onEventPerFormInMessageComponent}
        />
      ) : (
        <SingleRightMessage
          key={`right_msg_${index}`}
          message={message}
          user={message?.sendByUser}
          setMessageState={setMessageState}
          check={activeCheckbox[currentActiveRecipient?.id]?.selected}
          searchActive={inlineSearch}
          checkboxSelected={isCheckboxSelected}
          onEventPerform={onEventPerFormInMessageComponent}
        />
      );
    },
    [
      messages,
      view,
      currentActiveRecipient,
      activeCheckbox,
      setMessageState,
      timezone,
      inlineSearch,
      isCheckboxSelected,
      onEventPerFormInMessageComponent,
    ]
  );

  useEffect(() => {
    if (currentActiveRecipient && messages) {
      console.log("Inside useEffect");
      const lastRead = messages.find((itm) => !itm.messageRead && itm.inbound);
      console.log("Inside useEffect", lastRead);
      if (lastRead) setLastReadMessage(lastRead?.id);
    }
  }, [currentActiveRecipient, messages]);

  useEffect(() => {
    const _lastMsgType = messages?.[0]?.type || "SMS";
    dispatch(chatAction.setCurrentMessageType(_lastMsgType));
  }, [messages, dispatch]);

  // const waitForImagesToLoad = async () => {
  //   const images = scrollRef.current.querySelectorAll("img");
  //   if (images?.length <= 0) {
  //     return;
  //   }
  //   const promises = Array.from(images).map((elem) => {
  //     new Promise((resolve, reject) => {
  //       if (elem.complete) resolve();
  //       else {
  //         elem.onload = resolve;
  //         elem.onerror = resolve;
  //       }
  //     });
  //   });

  //   return Promise.all(promises);
  // };

  useLayoutEffect(() => {
    console.log("Messages Load", messages);
    // waitForImagesToLoad().then(() => {
    const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
    console.log(
      "Scroll values in useEffect",
      scrollTop,
      scrollHeight,
      clientHeight,
      previousScrollTop.current
    );

    if (scrollDirection === "next") {
      const newTop =
        previousScrollTop.current -
        (scrollHeight - previousScrollHeight.current);

      console.log("Scroll Values newtop", newTop);
      // setIsAutoScroll(true);
      // scrollRef.current.scrollTo({
      //   top: newTop,
      // });
      scrollRef.current.scrollTop = newTop;
    }

    previousScrollHeight.current = scrollHeight;
    if (isAPIProgress) {
      setTimeout(() => {
        setIsAPIProgress(false);
      }, 500);
    }
    // });
  }, [messages]);

  // useEffect(() => {
  //   // setTimeout(() => {}, 500);
  // }, [messages]);

  return (
    <MainChatPanel>
      <MessageWrapper>
        <MessageChat
          ref={scrollRef}
          id="scrollableDiv_normal"
          style={{
            height: 300,
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            flexDirection: "column-reverse",
          }}
        >
          {messages?.length ? (
            <>
              <InfiniteScroll
                onScroll={handleScroll}
                dataLength={messages.length}
                inverse={true}
                next={() => loadMore("prev")}
                style={{
                  display: "flex",

                  flexDirection: "column-reverse",
                }}
                hasMore={Boolean(conversation?.prevPageCursor)}
                loader={
                  <MDBox
                    width="100%"
                    display="flex"
                    justifyContent="center"
                    sx={{ overflow: "hidden" }}
                  >
                    <CircularProgress />
                  </MDBox>
                }
                scrollableTarget="scrollableDiv_normal"
              >
                {
                  // [...(!isPrev() ? [...messages].reverse() : messages)] // Reverse only for "next"
                  messages
                    .filter((itm) => {
                      if (currentMessageView === "SMS")
                        return itm.type === "SMS";
                      if (currentMessageView === "WHATSAPP")
                        return itm.type === "WHATSAPP";
                      return true;
                    })
                    .map((msg, index) => formatMessages(msg, index))
                }
              </InfiniteScroll>
            </>
          ) : (
            <NoContent
              heading="h5"
              style={{ height: "100%" }}
              message="You have no existing conversations with this number."
            />
          )}
        </MessageChat>
      </MessageWrapper>
      {scrollChatBottomBtn && (
        <SpeedDial
          onClick={scrollToBottom}
          ariaLabel="scroll to bottom"
          sx={{ position: "absolute", bottom: 38, right: 16 }}
          icon={
            <KeyboardArrowDown
              sx={{ height: "24px!important", width: "24px!important" }}
            />
          }
        />
      )}
    </MainChatPanel>
  );
};

export default memo(NConversation);
