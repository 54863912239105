import { setActivePhone } from "api/onlineStatus";
import { removeActivePhone } from "api/onlineStatus";
import { unscheduleEmailNotifications } from "api/settings";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useHttp from "./use-http";
import { chatAction } from "store/chat-slice";
import { useCallback } from "react";
import { USER_PHONE_QUEUE } from "constants/WsConstants";
import { useDebounce } from "use-debounce";
const header = {
  // 'auto-delete': true,
  // 'durable' : false
  // "x-ha-queues" : true,
  ack: 'client-individual'
};
const usePhoneStatus = (onMessageReceiveEvent,onCurrentReplyEvent) => {
    
    const dispatch = useDispatch();
    const stompClient = useSelector(state => state.app.stompClient);

    const { sendRequest: removeActivePhoneStatusFn } = useHttp(removeActivePhone,false,false);
    const { sendRequest: setActivePhoneStatusFn } = useHttp(setActivePhone,false,false);
    const { sendRequest: unscheduleEmailNotificationsFn } = useHttp(unscheduleEmailNotifications,false,false);

    const currentActivePhone = useSelector(state => state.chat.current_active_phone);
    const token = useSelector(state => state.user.token);
    const currentUser = useSelector(state => state.userDetails.details);
    const currentActiveRecipient = useSelector(state => state.chat.current_active_recipient);
    const [debouncedPhones] = useDebounce(currentUser?.phones, 500);
    
    const buildStatusPayload = useCallback(() => {
      return {
        user: {
          userId: currentUser?.userId,
          fullName: currentUser?.fullName,
          profilePic: currentUser?.profilePic,
        },
        recipientId: currentActiveRecipient?.id,
        phoneId: currentActivePhone?.phoneId,
        token: token,
        replying: true,
      };
    },[currentActivePhone,currentActiveRecipient,currentUser]);
  
    useEffect(() => {
      if (currentActivePhone && currentUser) {
        dispatch(chatAction.setCurrentActivePhone(currentActivePhone));
        const prevPhoneId = currentActivePhone.phoneId;
        setActivePhoneStatusFn({ token, phoneId: currentActivePhone.phoneId });
        //unschedule emais and unqueue the stale message for this user and phone
        unscheduleEmailNotificationsFn({phone: currentActivePhone.phone,token,userId : currentUser.userId});
        return () => {
          removeActivePhoneStatusFn({ token, phoneId: prevPhoneId });
        };
      }
    }, [currentActivePhone,currentUser]);

    //subscribe to all the numbers, available for logged in user
    useEffect(() => {
      const allSubscriptions = [];
      if (debouncedPhones && stompClient?.connected) {
        // for(const p of debouncedPhones) {
          const p = debouncedPhones[0];
          console.log("Subscribing for phone : {}",p?.phone);
          if(p){
            try{
              const s = stompClient?.subscribe(`${USER_PHONE_QUEUE(currentUser.userId,p.phone)}`,onMessageReceiveEvent,{...header,userId:currentUser?.userId});
              allSubscriptions.push(s);
            }catch(e){
              console.log(e);
            }
          }
        // }
      }
      return () => {
        allSubscriptions.forEach((ts) => {
          try {
            ts?.unsubscribe();
          } catch (error) {
            console.error('Error while unsubscribing:', error);
          }
        });
      }
    },[stompClient,debouncedPhones]);

  // SUBSCRIBE FOR ACTIVE RECIPIENT PHONE
    useEffect(() => {

      let realTimeTyping;
      if (stompClient && currentActivePhone && currentActiveRecipient) {    
        /// Subscribe for any user repliyng for the selected chat
        try{
          realTimeTyping = stompClient?.subscribe(`/topic/${currentActivePhone.phoneId}.${currentActiveRecipient?.id}`,onCurrentReplyEvent);
        }
        catch(e){console.log(e)};
      }
      return () => {
        if(realTimeTyping){
          const payload = buildStatusPayload();
          payload.replying = false;
          try{
            stompClient?.send(`/app/${currentActivePhone.phoneId}.${currentActiveRecipient?.id}`,{}, JSON.stringify(payload))
          }
          catch(e){}
          realTimeTyping?.unsubscribe();
        }
      };
    },[currentActivePhone, stompClient,currentActiveRecipient])    
}
export default usePhoneStatus;